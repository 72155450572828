<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                Postleitzahlen
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Firma</label>
                        <select class="form-control form-control-sm" v-model="params.company_id">
                            <option :value="null">-- Bitte wählen --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">

                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Von</label>
                        <input type="date" class="form-control form-control-sm" v-model="params.from">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Bis</label>
                        <input type="date" class="form-control form-control-sm" v-model="params.to">
                    </div>
                </div>
                <div class="col-md-4">
                    <label style="visibility: hidden">.</label> <br>
                    <button class="btn btn-sm btn-primary" @click="getZipcodes()">
                        <i class="fas fa-sync"></i>
                    </button>
                </div>
            </div>
            <div>
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>PLZ</th>
                            <th>Häufigkeit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(test, zip) in zipcodes" :key="zip">
                            <td>{{ zip }}</td>
                            <td>{{ test }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GeneralStatistics',
        title: 'Allgemeine Statistik',
        data() {
            return {
                companies: [],
                zipcodes: [],

                params: {
                    company_id: null,
                    from: null,
                    to: null,
                }
            }
        },
        methods: {
            getCompanies() {
                this.axios
                    .get("/companies")
                    .then((response) => {
                        this.companies = response.data.data;
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Beim laden der Firmen ist etwas schief gelaufen'
                        })
                    });
            },
            getZipcodes() {
                this.axios
                    .get("/checked-zipcodes", {
                        params: {
                            ...this.params
                        }
                    })
                    .then((response) => {
                            this.zipcodes = response.data;
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Beim laden der Postleitzahlen ist etwas schief gelaufen...'
                        });
                    })
            }
        },
        mounted() {
            this.getCompanies();
            this.getZipcodes();
        }
    }
</script>